import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/MdxPageLayout/index.jsx";
import ComparisonResultsPageHero from "../../components/ComparisonResultsPageHero";
import ComparisonResultPageTextWithImageSection from "../../components/ComparisonResultPageTextWithImageSection";
import ComparisonResultGrid from "../../components/ComparisonResultGrid";
import ComparisonResultColumnContent from "../../components/ComparisonResultColumnContent";
import ComparisonResultColumnContentBackgroundContainer from "../../components/ComparisonResultColumnContentBackgroundContainer";
import ComparisonResultGettingStartedBlock from "../../components/ComarisonResultPageGetStartedBlock";
import ComparisonPageRecommendations from "../../components/ComparisonPageRecommendations";
import ComparisonResultPageLearnMoreContainer from "../../components/ComparisonResultPageLearnMoreContainer";
import ComparisonResultHeroUserLocation from "../../components/ComparisonResultHeroUserLocation";
import HomeLoanIllustration from "../../../svg/ComparisonResultPageIllustrations/homeloan.svg";
import CCUSVG from "../../../svg/ComparisonResultGridLogos/homeloans/consumercreditunion.svg";
import BetterSVG from "../../../svg/ComparisonResultGridLogos/homeloans/better.svg";
import QuickenSVG from "../../../svg/ComparisonResultGridLogos/homeloans/quicken.svg";
import RocketSVG from "../../../svg/ComparisonResultGridLogos/homeloans/rocketmortgage.svg";
import BoASVG from "../../../svg/ComparisonResultGridLogos/homeloans/bankofamerica.svg";
export const pageMetaData = {
  pageTitle: "Compare Home Loan Products",
  pageDescription: "Loans From 3%",
  pageImagePath: "/homeloans.jpg",
  showNavbar: false,
  pageMetaTags: []
};
export const resultGridConfig = {
  columnConfiguration: {
    columns: [{
      index: 1,
      rows: [{
        index: 1,
        text: "Minimum Downpayment"
      }, {
        index: 2,
        text: "Online Quote"
      }, {
        index: 3,
        text: "Apply Online"
      }, {
        index: 4,
        text: "Best For"
      }, {
        index: 5,
        text: "Minimum Credit Score"
      }, {
        index: 6,
        text: "SSN Required For Quote"
      }, {
        index: 6,
        text: "About"
      }],
      expansionRows: []
    }, {
      index: 2,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "Consumer Credit Union",
          subText: "",
          imageKey: "ccu"
        }
      }, {
        index: 2,
        text: "3%",
        isPriceCell: true,
        showSavingText: false,
        disableDollarPrefix: true,
        showRating: true,
        rating: 4
      }, {
        index: 3,
        useIcon: true,
        icon: "tick"
      }, {
        index: 4,
        useIcon: true,
        icon: "tick"
      }, {
        index: 5,
        text: "Low APR"
      }, {
        index: 6,
        text: "620+"
      }, {
        index: 7,
        useIcon: true,
        icon: "tick"
      }, {
        index: 8,
        usePopover: true,
        popOverTitle: "About",
        popOverContents: "No annual fee.|Interest-only payment options.|Easy line advances within CCU Online Banking.|Competitive rates."
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://consumer-credit-union.4cna.net/c/1323808/548117/9094?u=https%3A%2F%2Fwww.myconsumers.org%2Fborrow%2Fconsumers-mortgages%2Freal-estate-center",
        openLinkExternally: true,
        text: "Sign Up",
        title: "Click to sign up to Consumer Credit Union",
        showIcon: false
      }
    }, {
      index: 3,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "Better.com",
          subText: "",
          imageKey: "better"
        }
      }, {
        index: 2,
        text: "3%",
        isPriceCell: true,
        showSavingText: false,
        disableDollarPrefix: true,
        showRating: true,
        rating: 4
      }, {
        index: 3,
        useIcon: true,
        icon: "tick"
      }, {
        index: 4,
        useIcon: true,
        icon: "tick"
      }, {
        index: 5,
        text: "Refinancing"
      }, {
        index: 6,
        text: "620+"
      }, {
        index: 7,
        useIcon: true,
        icon: "tick"
      }, {
        index: 8,
        usePopover: true,
        popOverTitle: "About",
        popOverContents: "Completely digital, transparent mortgage experience.|FHA Loans available.|Obtain purchase loans for up to $3 million.|Over $1B in loans originated since January 2015.|Get a quote in as little as 3 minutes.|$0 commission, $0 hidden fees"
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://welcome.better.com/get-a-better-mortgage/?utm_source=consumersadvocate&utm_campaign=prospecting_purchase&utm_content=medium_intent&utm_term=&pcuid=u5f2ec8ec7a4",
        openLinkExternally: true,
        text: "Sign Up",
        title: "Click to sign up to Better.com",
        showIcon: false
      }
    }, {
      index: 4,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "Quicken Loans",
          subText: "",
          imageKey: "quicken"
        }
      }, {
        index: 2,
        text: "3%",
        isPriceCell: true,
        showSavingText: false,
        disableDollarPrefix: true,
        showRating: true,
        rating: 3
      }, {
        index: 3,
        useIcon: true,
        icon: "tick"
      }, {
        index: 4,
        useIcon: true,
        icon: "tick"
      }, {
        index: 5,
        text: "Online Lending"
      }, {
        index: 6,
        text: "620+"
      }, {
        index: 7,
        useIcon: true,
        icon: "cross"
      }, {
        index: 8,
        usePopover: true,
        popOverTitle: "About",
        popOverContents: "Get their Best Rate Available 1.|Industry leading online tools & award-winning customer service.|Get a Pre-Authorization Letter to Buy a Home.|Jumbo Mortgage Rates Available.|Quicken Loans is the largest online mortgage lender in the U.S. 2"
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://www.quickenloans.com/l2/wham?qls=NDW_affqlcom.nrdreviews&aff_sub=d915061f7dde485aaab34df762a0beb7",
        openLinkExternally: true,
        text: "Sign Up",
        title: "Click to sign up to Quicken Loans",
        showIcon: false
      }
    }, {
      index: 5,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "Rocket Mortgage",
          subText: "",
          imageKey: "rocket"
        }
      }, {
        index: 2,
        text: "3%",
        isPriceCell: true,
        disableDollarPrefix: true,
        showSavingText: false,
        showRating: true,
        rating: 4
      }, {
        index: 3,
        useIcon: true,
        icon: "cross"
      }, {
        index: 4,
        useIcon: true,
        icon: "tick"
      }, {
        index: 5,
        text: "Customer Satisfaction"
      }, {
        index: 6,
        text: "620+"
      }, {
        index: 7,
        useIcon: true,
        icon: "tick"
      }, {
        index: 8,
        usePopover: true,
        popOverTitle: "About",
        popOverContents: "A streamlined loan application that can be completed entirely online.|Get pre-approved in minutes.|Receive custom mortgage solutions based on your credit and finances.|Automatically share information about your income and assets.|Customize loan terms and costs to fit your budget"
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://www.rocketmortgage.com/l2/bamv2?qls=CAO_affrktxx.mortgagexx&aff_sub=6ba1603080c2878884ca50a076a84f0b-1596455421454927-548313__1598693474_554113&qpv=icons2",
        openLinkExternally: true,
        text: "Sign Up",
        title: "Click to sign up to Rocket Mortgage",
        showIcon: false
      }
    }, {
      index: 6,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "Bank Of America",
          subText: "",
          imageKey: "boa"
        }
      }, {
        index: 2,
        text: "3%",
        isPriceCell: true,
        showSavingText: false,
        disableDollarPrefix: true,
        showRating: true,
        rating: 3
      }, {
        index: 3,
        useIcon: true,
        icon: "tick"
      }, {
        index: 4,
        useIcon: true,
        icon: "tick"
      }, {
        index: 5,
        text: "First Time Buyers"
      }, {
        index: 6,
        text: "620+"
      }, {
        index: 7,
        useIcon: true,
        icon: "tick"
      }, {
        index: 8,
        usePopover: true,
        popOverTitle: "About",
        popOverContents: "Allows borrowers to apply entirely online.|Offers down payment and closing cost assistance programs.|May give existing customers a discount on mortgage lender origination fees."
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://www.bankofamerica.com/mortgage/home-mortgage/",
        openLinkExternally: false,
        text: "Sign Up",
        title: "Click to sign up to Bank Of America",
        showIcon: false
      }
    }]
  }
};
export const _frontmatter = {};
const layoutProps = {
  pageMetaData,
  resultGridConfig,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <ComparisonResultsPageHero mdxType="ComparisonResultsPageHero">
      <h1>{`Compare home loan products`}</h1>
      <ComparisonResultPageTextWithImageSection images={[<HomeLoanIllustration imagekey="large-view" className="h-56 ml-auto" mdxType="HomeLoanIllustration" />, <HomeLoanIllustration imagekey="mobile-view" className="h-56 w-full" mdxType="HomeLoanIllustration" />]} mdxType="ComparisonResultPageTextWithImageSection">
        <p>{`On the surface, a mortgage might be one of the most easily-understood types of loans across the nation - but these loans are so much more complicated than 30-year terms and monthly mortgage payments. The process of finding a mortgage program that works for your financial needs and your housing goals means you'll need to do some research to find your ideal option. When you start comparing home loans, be sure you're asking the right questions.`}</p>
      </ComparisonResultPageTextWithImageSection>
      <ComparisonResultHeroUserLocation productCategory="Home Loan Products" mdxType="ComparisonResultHeroUserLocation" />
    </ComparisonResultsPageHero>
    <ComparisonResultGrid columnConfiguration={resultGridConfig.columnConfiguration} mdxType="ComparisonResultGrid">
  <CCUSVG className="w-full" imagekey="ccu" mdxType="CCUSVG" />
  <BetterSVG className="w-full" imagekey="better" mdxType="BetterSVG" />
  <QuickenSVG className="w-full" imagekey="quicken" mdxType="QuickenSVG" />
  <RocketSVG className="w-full" imagekey="rocket" mdxType="RocketSVG" />
  <BoASVG className="w-full" imagekey="boa" mdxType="BoASVG" />
    </ComparisonResultGrid>
    <ComparisonResultColumnContent blogContentCategory="loans" mdxType="ComparisonResultColumnContent">
      <ComparisonResultColumnContentBackgroundContainer mdxType="ComparisonResultColumnContentBackgroundContainer">
        <h2>{`What Should You Focus on When Comparing Home Loans?`}</h2>
        <h3>{`1. What Is the Minimum Downpayment?`}</h3>
        <p>{`A minimum downpayment is the absolute lowest amount you need to pay for your home's cost to secure the home loan. Depending on where you live and the type of loan you are aiming for, your minimum downpayment can range from as little as 3% to as much as 20%.`}</p>
        <p>{`Another factor to consider is whether your home loan is for a new home or for refinancing the mortgage on your current home; in this case, you might not have a downpayment at all.`}</p>
        <h4>{`Which Type of Loan Has the Lowest Down Payment?`}</h4>
        <p>{`Generally speaking, loans used by first-time buyers and low-income buyers tend to have the lowest down payments. For example, an FHA loan usually requires only a 3.5% downpayment, which is part of the reason why this type of loan is so popular.`}</p>
        <p>{`However, other loans have even smaller downpayments than that. Both VA Home Purchase and USDA loans have 0% minimum down payments, meaning these are loans that may be overall more affordable for certain financial situations.`}</p>
        <h4>{`Should You Look Into Down Payment Assistance?`}</h4>
        <p>{`Of course, if you do end up securing a loan that requires a larger downpayment than you can afford on your own, you can always look into any down payment assistance programs. Many of these programs are sponsored by local and state governments and have strict eligibility requirements that can help make up for the percentage of the down payment you are unable to meet.`}</p>
        <h3>{`2. What Is Your Credit Score?`}</h3>
        <p>{`Your credit score is one of the things that will either qualify or disqualify you from a particular loan. Credit scores provide the financial institution hosting your home loan with information about how well you can manage debts, which may make you seem more or less reliable. Most home loans have at least a minimum credit score that must be met.`}</p>
        <h4>{`What Is the Credit Score Range for Most Loans?`}</h4>
        <p>{`The average minimum credit score requirement for loans is between 620 and 680, which is in the green zone for good credit. The higher your credit score, the more likely you are to receive the loan and the lower your interest rates will be. The one exception to this average credit score range is with FHA loans, which sometimes allow for minimum credit scores between 500 and 580, depending on the lender.`}</p>
        <h3>{`3. Are There Any Upfront Fees?`}</h3>
        <p>{`Upfront fees are the costs that are due at signing for your home. One upfront fee is technically your down payment, but several other upfront fees factor into your home purchase or refinancing. For example, common upfront fees include credit checks, processing fees, underwriting fees, and escrow fees. The two most expensive upfront fees will be the MIP and the loan origination fees.`}</p>
        <h4>{`What Is MIP and Why Is It Necessary?`}</h4>
        <p>{`MIP stands for the Mortgage Insurance Premium commonly used in FHA-type loans and is essentially a guarantee for the lender that you will meet your mortgage payments. Some MIPs are paid upfront, while others are calculated as an annual bill that is part of your mortgage payments. This premium is typically only used when you are unable to pay 10-20% percent of your down payment.`}</p>
        <h4>{`How Are Loan Origination Fees Calculated?`}</h4>
        <p>{`Loan origination fees are common to most types of loans, including loans for homeownership. An origination fee is a charge from the lender for the cost of processing your loan. Origination fees are generally calculated as a small percentage of your entire loan, usually around 1% of the loan.`}</p>
        <h3>{`4. What Are the Loan Interest Rates?`}</h3>
        <p>{`Your loan's interest rate will be determined by the amount of your loan, the length of your loan term, and your credit score. Interest rates are factored into each of your mortgage payments and directly impact how quickly you can pay off your loan. If you're trying to decide between two similar loans, then the interest rate may be the final factor you need to weigh.`}</p>
        <h4>{`Why Should You Consider Adjustable-Rate Mortgage?`}</h4>
        <p>{`Interest rates come in a few different forms, and for mortgage loans, these rates are typically fixed for the loan duration. However, mortgage loans also offer adjustable-rate mortgages, which are interest rates that are fixed for 5, 7, or 10 years, and then can be adjusted if you plan to continue living in the home. This type of interest rate program is a good option for homeowners who don't want 30-year mortgages and prefer to have the option of refinancing or moving before the term is over.`}</p>
        <h3>{`5. What Is the Expense of the Neighborhood?`}</h3>
        <p>{`The cost of your house - and the rest of your neighborhood - will also determine the type of loan you qualify for. This is usually because most lenders have caps for how much they are willing to loan, which means your chosen lender may not be able to approve your loan application due to financial limitations. Fortunately, there's a fix for that.`}</p>
        <h4>{`Why Jumbo Loans Might Be a Good Idea`}</h4>
        <p>{`A Jumbo Loan is a type of loan that is non-conforming and is ideal for high-cost communities. Jumbo Loans allow buyers to apply for low-interest loans on more expensive homes, which may be useful if your state has a higher cost of living than the national average.`}</p>
      </ComparisonResultColumnContentBackgroundContainer>
      <ComparisonResultGettingStartedBlock mdxType="ComparisonResultGettingStartedBlock" />
      <ComparisonPageRecommendations requestingProductCategory="home loans" mdxType="ComparisonPageRecommendations" />
      <ComparisonResultPageLearnMoreContainer mdxType="ComparisonResultPageLearnMoreContainer">
        <h3>{`Learn More`}</h3>
        <p>{`When it's time to start comparing home loans, it's time to start paying attention to the details. While the loan amount and the interest rates may be the most obvious points of comparison, you also need to pay attention to other numbers - particularly the downpayment, your credit score, upfront fees, and the cost of the neighborhood you plan to move into. If you want more information on the best way to compare home loans, visit `}<a parentName="p" {...{
            "href": "/"
          }}>{`The Comparison Company`}</a>{` today!`}</p>
      </ComparisonResultPageLearnMoreContainer>
    </ComparisonResultColumnContent>



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      